(function($) {
    //add Element Actions on elementor init
    $(window).on('elementor/frontend/init', function() {


        elementorFrontend.hooks.addAction( 'frontend/element_ready/form.default', function($scope){
            $scope.find('input, textarea').focus(function(e) {
                var name = $(this).attr('id');
                if ($(this).val()) {
                    $('label[for="' + name + '"]').addClass('active');
                }
                if ($('label[for="' + name + '"]').length > 0) {
                    $('label[for="' + name + '"]').addClass('active');
                }
            });

            $scope.find('input, textarea').blur(function(e) {
                var name = $(this).attr('id');
                if ($('label[for="' + name + '"]').length > 0) {
                    $('label[for="' + name + '"]').removeClass('active');
                }
                if ($(this).val()) {
                    $('label[for="' + name + '"]').addClass('active');
                }
            });
        });

        elementorFrontend.hooks.addAction( 'frontend/element_ready/n1-custom-swiper.default', function($scope){
            var thisSwipterId = $scope.find('.swiper-container').attr('id');
            var swiper = new Swiper('#'+thisSwipterId, {
                slidesPerView: 'auto',
                spaceBetween: 24,
                centeredSlides: false,
                loop: false,
                pagination: {
                    el: '#'+thisSwipterId + ' .swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '#'+thisSwipterId + ' .swiper-button-next',
                    prevEl: '#'+thisSwipterId + ' .swiper-button-prev',
                },
            });
        });

        elementorFrontend.hooks.addAction( 'frontend/element_ready/accordion.default', function($scope){
            window.setTimeout(function(){
                if(window.location.hash !== ''){
                    if($(window.location.hash).parent('.elementor-tab-content').length > 0){
                        $(window.location.hash).parent('.elementor-tab-content').prev().find('a').click();
                        $('html, body').animate({
                            scrollTop: $(window.location.hash).offset().top - 290,
                        }, 1000);
                    }
                }
            },500);
        });

    });
}(jQuery));
